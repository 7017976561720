import { useMemo } from "react";

import { Routes } from "@/constants";
import { useWordPressCategoriesQuery } from "@/entities/wordpress/api/categories";
import { Category } from "@/entities/wordpress/api/posts/types";
import { BlogPostCategoriesItem } from "@/features/public/blog/types";

import { UNCATEGORIZED_CATEGORY_SLUG } from "../constants";

type CategoryWithChild = Category & {
  child?: CategoryWithChild;
};

type UsePostCategoriesProps = {
  postId: number | undefined;
  withRootItem?: boolean;
};

const BLOG_ITEM_DATA = { href: Routes.News, title: "Blog" };

const getNestedCategories = (data: Category[] | undefined) => {
  const categoriesMap: {
    [key: number]: CategoryWithChild;
  } = {};
  const nestedCategories: CategoryWithChild[] = [];

  if (!data) {
    return [];
  }

  // Create a map of categories using their ids as keys
  for (const category of data) {
    if (!(category.id in categoriesMap)) {
      categoriesMap[category.id] = { ...category };
    }
  }

  // Build the nested categories hierarchy
  for (const category of data) {
    if (category.parent !== 0) {
      const parentCategory = categoriesMap[category.parent];
      parentCategory.child = categoriesMap[category.id];
    } else {
      nestedCategories.push(categoriesMap[category.id]);
    }
  }

  return nestedCategories.length > 0 ? nestedCategories : [];
};

const expandNestedCategories = (
  nestedCategories: CategoryWithChild[],
  withRootCategory
) => {
  const items: BlogPostCategoriesItem[] = [];

  if (nestedCategories.length === 0) {
    return items;
  }

  const expandCategory = (category: CategoryWithChild, path: string[] = []) => {
    const { slug, name, child } = category;
    const newPath = [...path, slug];
    const href = `${Routes.News}/${newPath.join("/")}`;

    items.push({ href, title: name });

    if (child) {
      expandCategory(child, newPath);
    }
  };

  expandCategory(nestedCategories[0]);

  const rootItem: BlogPostCategoriesItem[] = withRootCategory
    ? [BLOG_ITEM_DATA]
    : [];

  return items[0].href?.startsWith(Routes.News)
    ? rootItem.concat(items)
    : items;
};

export const usePostCategories = ({
  postId,
  withRootItem = true,
}: UsePostCategoriesProps) => {
  const { data } = useWordPressCategoriesQuery({ post: postId });

  const filteredCategories = useMemo(
    () =>
      (data ?? []).filter(
        (category) => category.slug !== UNCATEGORIZED_CATEGORY_SLUG
      ),
    [data]
  );

  return useMemo(() => {
    const nestedCategories = getNestedCategories(filteredCategories);

    return expandNestedCategories(nestedCategories, withRootItem);
  }, [filteredCategories, withRootItem]);
};
