import axios from "axios";
import { QueryClient, QueryFunctionContext, useQuery } from "react-query";

import { WP_API_BASE } from "../constants";
import { Media } from "./types";

const makeKey = (id: number) => ["fetchMedia", id] as const;

export const apiFetchWordPressMedia = ({
  queryKey: [_, id],
}: QueryFunctionContext<ReturnType<typeof makeKey>>): Promise<Media> =>
  axios
    .get<Media>(`${WP_API_BASE}/media/${id}?_fields=media_details`)
    .then(({ data }) => data);

export async function apiFetchWordPressMediaInitialData<Media>(id: number) {
  const { data } = await axios.get<Media>(
    `${WP_API_BASE}/media/${id}?_fields=media_details`,
    {
      validateStatus: (status) => status < 500,
    }
  );

  return data;
}

export const useWordPressMediaQuery = (mediaId: number, initialData?: any) =>
  useQuery(makeKey(mediaId), apiFetchWordPressMedia, {
    keepPreviousData: true,
    staleTime: Number.POSITIVE_INFINITY,
    ...(initialData && { initialData }),
  });

export const prefetchWordPressMedia = (
  queryClient: QueryClient,
  mediaId: number
) => queryClient.prefetchQuery(makeKey(mediaId), apiFetchWordPressMedia);
