export enum CertificatePrices {
  landlord_gas_certificate = " / 1 year",
  landlord_eic_report = " / 5 years",
  landlord_epc_certificate = " / 10 years",
}

export enum ServiceCodes {
  deposit_replacement_fee = "deposit_replacement_fee",
  holding_deposit = "holding_deposit",
  content_insurance = "tenant_content_insurance",
  landlord_check_out = "landlord_check_out",
  landlord_eic_report = "landlord_eic_report",
  landlord_epc_certificate = "landlord_epc_certificate",
  landlord_extra_listing = "landlord_extra_listing",
  landlord_gas_certificate = "landlord_gas_certificate",
  landlord_gas_safety_boiler_service = "landlord_gas_safety_boiler_service",
  landlord_home_emergency_insurance = "landlord_home_emergency_insurance",
  landlord_inventory_check_in_furnished = "landlord_inventory_check_in_furnished",
  landlord_inventory_furnished = "landlord_inventory_furnished",
  landlord_inventory_unfurnished = "landlord_inventory_unfurnished",
  landlord_inventory = "landlord_inventory",
  landlord_inventory_check_in = "landlord_inventory_check_in",
  landlord_listing_fee = "landlord_listing_fee",
  landlord_photographs_floor_plans = "landlord_photographs_floor_plans",
  landlord_rent_guarantee_insurance = "landlord_rent_guarantee_insurance",
  landlord_life_insurance = "landlord_life_insurance",
  landlord_building_insurance = "landlord_building_insurance",
  landlord_referencing_report = "landlord_referencing_report",
  lei_toba_mashroom = "lei-toba-mashroom",
}

export enum DocumentTypeCodes {
  identity = "identity",
  bill = "bill",
  contract = "contract",
  certificate = "certificate",
  maintenance = "maintenance",
  photo = "photo",
  photo_raw = "photo_raw",
  plan = "plan",
  plan_raw = "plan_raw",
  home_emergency_insurance = "home_emergency_insurance",
  inventory = "inventory",
  check_out_report = "check_out_report",
  check_in_report = "check_in_report",
  rent_guarantee_insurance = "rent_guarantee_insurance",
  deposit_registration_certificate = "deposit_registration_certificate",
  life_insurance = "life_insurance",
  building_insurance = "building_insurance",
  referencing_report = "referencing_report",
  contents_insurance = "contents_insurance",
  other = "other",
  legal_expenses_insurance = "legal_expenses_insurance",
  gas_safety_certificate = "gas_safety_certificate",
  eicr = "eicr",
  epc = "epc",
}
