import { useMemo } from "react";

import { WordPressPost } from "@/entities/wordpress/api/posts/types";

import {
  // AREA_GUIDES_CATEGORY_ID,
  GUIDES_CATEGORY_ID,
} from "../constants";

const VHOST = process.env.VHOST;

export const usePostLinkParams = (
  post: WordPressPost,
  productLink: string | null | undefined = null
) =>
  useMemo(() => {
    // link to product page
    if (productLink) {
      return {
        as: productLink,
        href: productLink,
      };
    }

    const newSlug = `${post.slug}-${post.id}`;

    if (post.categories.includes(GUIDES_CATEGORY_ID)) {
      // link to guides category
      return {
        pathname: "/guides/[post]",
        query: { slug: post.slug, id: post.id },
        as: `/guides/${newSlug}`,
        href: `${VHOST}/guides/${newSlug}`,
      };
    }

    // TODO: remove area-guides
    // if (post.categories.includes(AREA_GUIDES_CATEGORY_ID)) {
    //   // link to area guides category
    //   return {
    //     pathname: "/area-guides/[post]",
    //     query: { slug: post.slug, id: post.id },
    //     as: `/area-guides/${newSlug}`,
    //     href: `${VHOST}/area-guides/${newSlug}`,
    //   };
    // }

    // link to other categories
    return {
      pathname: "/blog/[post]",
      query: { slug: post.slug, id: post.id },
      as: `/blog/${newSlug}`,
      href: `${VHOST}/blog/${newSlug}`,
    };
  }, [post.categories, post.id, post.slug, productLink]);
