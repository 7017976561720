import axios from "axios";
import { QueryClient, QueryFunctionContext, useQuery } from "react-query";

import { WP_API_BASE } from "../constants";
import { WordPressPost } from "./types";

interface Params {
  page?: number;
  categories?: number[] | null;
  per_page?: number;
  categories_exclude?: number[] | null;
  tags?: number[] | null;
}

const makeKey = (params: Params) =>
  ["fetchPosts", params, params.categories] as const;

export const fetchWordPressPosts = async (params: Params) => {
  const { data, headers } = await axios.get<WordPressPost[]>(
    `${WP_API_BASE}/posts`,
    {
      params,
    }
  );

  return {
    data,
    totalPages: Number(headers["x-wp-totalpages"]),
    total: Number(headers["x-wp-total"]),
  };
};

export const fetchWordPressPostsQueryFn = ({
  queryKey: [_, params],
}: QueryFunctionContext<ReturnType<typeof makeKey>>) =>
  fetchWordPressPosts(params);

export const fetchWordPressPostsWithQueryClient = (
  queryClient: QueryClient,
  params: Params
) => queryClient.fetchQuery(makeKey(params), fetchWordPressPostsQueryFn);

export const useWordPressPostsQuery = (
  params: Params,
  options?: {
    initialData?: any;
    keepPreviousData?: boolean;
  }
) =>
  useQuery(makeKey(params), fetchWordPressPostsQueryFn, {
    keepPreviousData: options?.keepPreviousData ?? true,
    staleTime: Number.POSITIVE_INFINITY,
    ...(options?.initialData && { initialData: options.initialData }),
  });

// TODO: rename prefetchWordPressPostsWithQueryClient
export const prefetchWordPressPosts = (
  queryClient: QueryClient,
  params: Params
) => queryClient.prefetchQuery(makeKey(params), fetchWordPressPostsQueryFn);

const makeSingleKey = (id: string) => ["fetchPost", id] as const;

// TODO: rename fetchWordPressPostQueryFn
const apiFetchWordPressPost = ({
  queryKey: [_, id],
}: QueryFunctionContext<ReturnType<typeof makeSingleKey>>) =>
  axios
    .get<WordPressPost>(`${WP_API_BASE}/posts/${id}`)
    .then(({ data }) => data);

// TODO: rename to fetchWordPressPostWithQueryClient
export const fetchWordPressPost = (queryClient: QueryClient, id: string) =>
  queryClient.fetchQuery(makeSingleKey(id), apiFetchWordPressPost);

export const useWordPressPostQuery = (id: string) =>
  useQuery(makeSingleKey(id), apiFetchWordPressPost, {
    keepPreviousData: true,
  });

// TODO: rename prefetchWordPressPostWithQueryClient
export const prefetchWordPressPost = (queryClient: QueryClient, id: string) =>
  queryClient.prefetchQuery(makeSingleKey(id), apiFetchWordPressPost);
