import axios from "axios";
import { QueryClient, QueryFunctionContext, useQuery } from "react-query";

import { WP_API_BASE } from "../constants";
import { Category } from "../posts/types";

const makeSingleCategoryKey = (id: number) =>
  ["wordpress_category", id] as const;

const makeCategoriesKey = (params: Params) =>
  ["wordpress_categories", params] as const;

interface Params {
  page?: number;
  per_page?: number;
  categories_exclude?: number[] | null;
  post?: number;
}

export const fetchWordPressCategories = async (params: Params) => {
  const { data } = await axios.get<Category[]>(`${WP_API_BASE}/categories`, {
    params,
  });

  return data;
};

const fetchCategoriesQueryFn = ({
  queryKey: [_, params],
}: QueryFunctionContext<ReturnType<typeof makeCategoriesKey>>) =>
  fetchWordPressCategories(params);

export const fetchWordPressCategoriesWithQueryClient = (
  queryClient: QueryClient,
  params: Params
) => queryClient.fetchQuery(makeCategoriesKey(params), fetchCategoriesQueryFn);

const apiFetchWordPressCategory = ({
  queryKey: [_, id],
}: QueryFunctionContext<ReturnType<typeof makeSingleCategoryKey>>) =>
  axios
    .get<Category>(`${WP_API_BASE}/categories/${id}`)
    .then(({ data }) => data);

export const fetchWordPressCategory = (queryClient: QueryClient, id: number) =>
  queryClient.fetchQuery(makeSingleCategoryKey(id), apiFetchWordPressCategory);

export const useWordPressCategoryQuery = (categoryId: number) =>
  useQuery(makeSingleCategoryKey(categoryId), apiFetchWordPressCategory);

export const useWordPressCategoriesQuery = (params: Params) =>
  useQuery(makeCategoriesKey(params), fetchCategoriesQueryFn);
