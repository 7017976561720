import React, { FC } from "react";

import Head from "next/head";

import { useAttachBot } from "@/hooks";

import { InvitePageLayoutProps } from "./types";

import styles from "./styles.module.scss";

export const InvitePageLayout: FC<InvitePageLayoutProps> = ({
  children,
  description,
  title,
}) => {
  useAttachBot();

  return (
    <div className={styles.root}>
      <Head>
        {title && <title>{title}</title>}
        {description && (
          <meta key="description" content={description} name="description" />
        )}
      </Head>
      <div className={styles.main}>{children}</div>
    </div>
  );
};
