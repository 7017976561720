import { useMemo } from "react";

import { useRouter } from "next/router";

import { extractSingleQueryParam } from "@/lib/utils";

import { ALL_CATEGORY_DATA } from "../constants";
import { Category } from "../pages/news/Categories/types";

export const useCurrentCategories = (
  categories: Category[] | undefined
): {
  currentCategory: Category | undefined;
  currentSubcategory: Category | undefined;
} => {
  const router = useRouter();
  const categorySlug = extractSingleQueryParam(router?.query.category);
  const subcategorySlug = extractSingleQueryParam(router?.query.subcategory);

  return useMemo(() => {
    const allCategories = categories ?? [];

    const currentCategory =
      allCategories.find(
        (category) => category.parent === 0 && category.slug === categorySlug
      ) ?? ALL_CATEGORY_DATA;

    const currentSubcategory = allCategories.find(
      (category) => category.parent !== 0 && category.slug === subcategorySlug
    );

    return {
      currentCategory,
      currentSubcategory,
    };
  }, [categories, categorySlug, subcategorySlug]);
};
