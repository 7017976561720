import axios from "axios";
import { QueryClient, QueryFunctionContext, useQuery } from "react-query";

import { WP_API_BASE } from "../constants";

export interface Author {
  avatar_urls: { 24: string; 48: string; 96: string };
  name: string;
}
const makeKey = (params: number) => ["wordpress_authors", params] as const;

export const apiFetchWordPressAuthor = ({
  queryKey: [_, authorId],
}: QueryFunctionContext<ReturnType<typeof makeKey>>) =>
  axios
    .get<Author>(`${WP_API_BASE}/users/${authorId}`)
    .then(({ data }) => data);

export const useWordPressAuthorQuery = (authorId: number) =>
  useQuery(makeKey(authorId), apiFetchWordPressAuthor, {
    keepPreviousData: true,
  });

export const fetchWordPressAuthor = (
  queryClient: QueryClient,
  authorId: number
) => queryClient.fetchQuery(makeKey(authorId), apiFetchWordPressAuthor);

export const prefetchWordPressAuthor = (
  queryClient: QueryClient,
  authorId: number
) => queryClient.prefetchQuery(makeKey(authorId), apiFetchWordPressAuthor);
