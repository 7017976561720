import { getPageTitleWithSuffix } from "@/lib/utils";

import { Category } from "./pages/news/Categories/types";

// TODO: Move to src/constants/
export const BLOG_REVALIDATE_TIME_SEC = 600;

export const NEWS_PAGE_TITLE = getPageTitleWithSuffix(
  "Blog | Property rental news | Tips for tenants and landlords"
);
export const NEWS_PAGE_DESCRIPTION =
  "Need advice on maximising your role as a landlord or a rental tenant? Look no further than the Mashroom blog for all the latest rental and tenancy news.";

export const AREA_GUIDES_PAGE_TITLE = getPageTitleWithSuffix(
  "Local area guides | Best places to live in UK"
);
export const AREA_GUIDES_PAGE_DESCRIPTION =
  "Read our comprehensive local area guides for detailed information about the areas that you want to live in and the things to do there.";

export const UNCATEGORIZED_CATEGORY_ID = 1;
export const UNCATEGORIZED_CATEGORY_SLUG = "uncategorized";
export const AREA_GUIDES_CATEGORY_ID = 108;
export const GUIDES_CATEGORY_ID = 502;
export const IDS_CATEGORIES_EXCLUDE: number[] = [AREA_GUIDES_CATEGORY_ID];

export const BLOG_PER_PAGE = 10;
export const BLOG_AREA_GUIDES_PER_PAGE = 4;
export const AREA_GUIDES_PER_PAGE = 10;

export const ALL_CATEGORY_DATA: Category = {
  id: 0,
  slug: "all",
  name: "Blog",
  description: "",
  parent: 0,
};
