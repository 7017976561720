import React, { FC } from "react";

import Head from "next/head";

import cn from "classnames";

import { Footer } from "@/components/layout/Footer";
import { Header } from "@/components/layout/Header";
import { useAttachBot } from "@/hooks";

import { ServicePageLayoutProps } from "./types";

import styles from "./styles.module.scss";

export const ServicePageLayout: FC<ServicePageLayoutProps> = ({
  children,
  description,
  newFont,
  title,
  keywords,
  headerTransparentOnTop,
  withRegulatoryNotice,
}) => {
  useAttachBot();

  return (
    <div className={styles.root}>
      <Head>
        {title && <title>{title}</title>}
        {description && (
          <meta key="description" content={description} name="description" />
        )}
        {keywords && <meta key="keywords" content={keywords} name="keywords" />}
      </Head>

      <div className={cn(styles.main, { [styles.newFont]: newFont })}>
        <Header transparentOnTop={headerTransparentOnTop} />
        <div className={styles.mainContent}>{children}</div>
      </div>
      <Footer withRegulatoryNotice={withRegulatoryNotice} />
    </div>
  );
};
