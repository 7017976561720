import { DocumentTypeCodes, ServiceCodes } from "./service-codes";

export const certificateCodes = [
  ServiceCodes.landlord_eic_report,
  ServiceCodes.landlord_epc_certificate,
  ServiceCodes.landlord_gas_certificate,
];

export const insuranceCodes = [
  ServiceCodes.landlord_home_emergency_insurance,
  ServiceCodes.landlord_rent_guarantee_insurance,
  ServiceCodes.landlord_life_insurance,
  ServiceCodes.content_insurance,
];

export const insuranceTypeCodes = [
  DocumentTypeCodes.home_emergency_insurance,
  DocumentTypeCodes.rent_guarantee_insurance,
  DocumentTypeCodes.contents_insurance,
  DocumentTypeCodes.life_insurance,
];

export const ADVERTISING_STORAGE_KEY = "LINE_ADVERT_BANNER";
export const ENTRYPOINT_STORAGE_KEY = "SIGNUP_ENTRYPOINT";
export const PERSONAL_DETAILS_STORAGE_KEY = "PERSONAL_DETAILS";
export const FBE_ORDERID_STORAGE_KEY = "FBE_ORDERID";
